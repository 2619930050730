import React from "react"
import Layout from "../../components/layoutEn"
import SEO from "../../components/layout/seo"

import standProjMain from "../../img/standProjMain.png"
import p1 from "../../img/y4420_11.jpg"
import p14 from "../../img/y4420_14.jpg"
import p17 from "../../img/y4420_17.jpg"
import standMainImg03 from "../../img/y4420_1.jpg"

const InterStandY4420 = () => (
  <Layout>
    <SEO title="M2MTech" />
    <div className="interStandWrap">
      <div className="sectionWrap StandOverview y4420Overview">
        <p className="standProjMain">
          <img src={standProjMain} alt="" />
        </p>
        <div className="textWrap">
          <h2>
            International <span>standard</span>
          </h2>
          <h3 style={{ paddingTop: 20 }}>ITU-T SG20 Y.4420</h3>
          <p className="bodyText">
            The elevator control framework jointly developed by M2MTech and ETRI
            was officially approved as an international standard in July 2021 as
            an 'IoT-based monitoring and management framework for elevators' at
            the International Telecommunication Union (ITU-T) SG20 international
            conference. Following the contribution of Elsa solution to the ITU-T
            SG20 Y-series as a high-tech elevator remote control monitoring
            system in 2020, M2MTech and ETRI have been working on the 'IoT
            gateway protocol and data for smart elevators for 3 years from 2018
            by the Ministry of Science and ICT. It is a domestic and
            international standard developed during the 'model standard
            development' project.
          </p>
        </div>
      </div>
      <div className="sectionWrap y4420">
        <div className="textWrap">
          <h2>ITU-T SG20 Y.4420</h2>
          <p>
            <img src={standMainImg03} alt="" />
          </p>
          <p className="bodyText">
            This standard is intended to ensure interoperability between
            elevator control panels and IoT gateways in the elevator industry.
            The purpose of elevator remote management, monitoring, and
            inspection by use common data for elevator safety and digital
            transformation of non-face-to-face maintenance work in a closed
            ecosystem with each manufacturer's own data model and protocol. It
            is to lay the foundation for creating new services through system
            interoperability in the elevator industry ecosystem and to provide
            people with elevator safety and convenience services.
          </p>
        </div>
      </div>
      <div className="sectionWrap ituContribution y4420Content">
        <div className="textWrap">
          <h2>Content of the standard</h2>
          <p className="bodyText">
            "Framework of Internet of things based monitoring and management for
            lifts"
            <br />
            This standard is an elevator industry standard for providing life
            safety and convenience services for elevators among system assets of
            apartment complexes or buildings. This is to promote stable and safe
            interoperability and control with interoperability in a reality
            where it is difficult to interlock between facility asset systems in
            a building and integrate heterogeneous types because the
            communication protocol is different for each elevator
            manufacturer/model. We defined the elevator structure and functional
            requirements between the elevator control panel and the IoT gateway,
            as well as a common serial communication protocol and data model. To
            this end, we have developed and applied standards by operating a
            professional standards committee with over 30 organizations
            including large corporations (Hyundai, Otis, TKE), small and
            medium-sized enterprises (SMEs), the Elevator Society, and Korea
            Elevator Safety Corporation to increase the reliability of standard
            adaptability.
          </p>
        </div>
        <div className="standMainImg">
          <p className="standMainImg01">
            <img src={p17} alt="" />
          </p>
          <p className="standMainImg02">
            <img src={p14} alt="" />
          </p>
          <p className="standMainImg03">
            <img src={p1} alt="" />
          </p>
        </div>
      </div>
      <div className="sectionWrap standServices">
        <div className="textWrapMain">Applications</div>

        <ul>
          <li>
            - Elevator manufacturer independent IoT gateway product
            development/standard demonstration :
            <span>
              Installed/operated at Hyundai, Otis, TKE, and small and
              medium-sized enterprises (SMEs) elevators installed in apartment
              houses, department stores, and production plants.
            </span>
          </li>
          <li>
            - public sector
            <span>
              Applied to LH elevator integrated platform design standard
              specification (Elevator University Research Service Result Report
              in 2021)
            </span>
            <span>
              Opinion gathering through LH working-level meeting and governance
              meeting of institutional heads of domestic elevator industry.
            </span>
            <span>
              Applied as standard for LH elevator integrated management platform
            </span>
            <span>
              Korea Railroad Corporation enforcement service in progress
            </span>
          </li>
          <li>
            - smart home field
            <span>
              Various convergence new industrial ecosystems can be expanded by
              linking smart home services within the apartment complex and
              external living services.
            </span>
          </li>
          <li>
            - Robot delivery service field
            <span>
              Laying the foundation for future-leading services such as elevator
              robot delivery service
            </span>
          </li>
          <li>
            - safety standard system
            <span>Used as a standard in the elevator industry </span>
            <span>
              Establishment of a predictive maintenance system in which
              management entities such as local governments and building owners
              meet elevator safety standards.
            </span>
          </li>
          <li>
            - Resident benefit
            <span>
              Zero out elevator accidents and establishment of a safety culture
              by providing smart elevator service predictive maintenance
              technology
            </span>
          </li>
          <li>
            - Elevator maintenance field
            <span>
              Improving the repair quality of the IoT-based elevator maintenance
              business in the time of digital transformation
            </span>
          </li>
          <li>
            - Law/Institution
            <span>
              Applied to the Elevator Safety Management Act/System, it is
              defined as Article 13-2 (Remote Management Function of Elevator)
              in the 「Regulations on Safe Operation and Management of
              Elevators」
            </span>
          </li>
          <li>
            - Contribute to vitalization of the elevator industry by
            establishing TTA standards, national standards, and international
            standards
            <span>
              .TTA standards : IoT gateway for remote elevator monitoring (※
              TTAK.KO-10.1267-Part 1, 2, 3)
            </span>
            <span>
              . national standard : The Industrial Machinery Technology Council
              is in progress to establish a national standard for IoT gateway
              for remote monitoring of elevators. National standard enacted in
              the first half of 2022
            </span>
            <span>
              . international standard : ITU-T SG20 Y.4420 (2021.07) “Framework
              of Internet of things based monitoring and management for lifts”
            </span>
          </li>
        </ul>
      </div>
    </div>
  </Layout>
)

export default InterStandY4420
